import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../functions/api";

const MatchAnalytics = () => {
  const [data, setData] = useState(null);

  const { match } = useParams();

  const getData = async () => {
    try {
      const data = await api.get("/match-analytics/" + match);
      setData(data);
    } catch (err) {
      console.log("🚀 ~ getData ~ err:", err);
    }
  };

  useEffect(() => {
    getData();
    const int = setInterval(() => {
      getData();
    }, 10000);
    return () => clearInterval(int);
  }, []);

  return (
    <div className="analytics">
      {data && (
        <div className="cards">
          <div className="card">
            <span>Team</span>
            <h2>{data.team}</h2>
          </div>
          <div className="card">
            <span>Ball to Ball</span>
            <h2>{data.ballToBall}</h2>
          </div>

          <div className="card">
            <span>Sessions</span>
            <h2>{data.sessions}</h2>
          </div>
          <div className="card">
            <span>Total Profit</span>
            <h2>{data.total}</h2>
          </div>
        </div>
      )}
    </div>
  );
};

export default MatchAnalytics;
