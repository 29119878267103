import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    value: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;
export const userReducer = userSlice.reducer;

const pinSlice = createSlice({
  name: "pin",
  initialState: {
    value: false,
  },
  reducers: {
    setPin: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setPin } = pinSlice.actions;
export const pinReducer = pinSlice.reducer;

const timeSlice = createSlice({
  name: "time",
  initialState: {
    value: null,
  },
  reducers: {
    setTime: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setTime } = timeSlice.actions;
export const timeReducer = timeSlice.reducer;
