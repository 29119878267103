import { useState, useEffect } from "react";
import { TextInput } from "react-materialize";

import api from "../../functions/api";

const Analytics = () => {
  const [data, setData] = useState([]);
  const [date, setDate] = useState(null);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const filters = [
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "This Week", value: "thisWeek" },
    { label: "This Month", value: "thisMonth" },
  ];

  const getData = async (page, search, id) => {
    const res = await api.get(
      `/analytics?date=${date || ""}&from=${from || ""}&to=${to || ""}`
    );
    setData(res);
  };

  useEffect(() => {
    getData();
  }, [date, to, from]);

  return (
    <div className="analytics">
      <div className="filteres">
        {filters.map((item) => (
          <div
            className={`filter ${
              item.value === date && item.value && "active"
            }`}
            onClick={() => setDate(item.value)}
          >
            {item.label}
          </div>
        ))}

        <div className="date-picker">
          <TextInput
            label="From"
            type="date"
            value={from}
            onChange={(e) => {
              setFrom(e.currentTarget.value);
              setDate(null);
            }}
          />
        </div>

        <div className="date-picker">
          <TextInput
            label="To"
            type="date"
            value={to}
            onChange={(e) => {
              setTo(e.currentTarget.value);
              setDate(null);
            }}
          />
        </div>

        <button
          className="btn"
          onClick={() => {
            setDate(null);
            setFrom(null);
            setTo(null);
          }}
          style={{ marginLeft: 30 }}
        >
          Clear
        </button>
      </div>

      <div className="cards">
        <div className="card">
          <span>Total Recharge</span>
          <h2>{data.totalRecharge}</h2>
        </div>
        <div className="card">
          <span>Total Withdraw</span>
          <h2>{data.totalWithdraw}</h2>
        </div>
        <div className="card">
          <span>Profit</span>
          <h2>{data.profit}</h2>
        </div>
        <div className="card">
          <span>Total wallet</span>
          <h2>{data.totalWallet}</h2>
        </div>
        <div className="card">
          <span>Net Profit</span>
          <h2>{data.netProfit}</h2>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
