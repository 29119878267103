import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import api from "../../functions/api";
import { Switch } from "react-materialize";

const Manage = () => {
  const [x, setX] = useState(null);
  const [teamAChance, setTeamAChance] = useState(0);
  const [teamBChance, setTeamBChance] = useState(0);
  const [teamAX, setTeamAX] = useState(2);
  const [teamBX, setTeamBX] = useState(2);
  const [firstTime, setFirstTime] = useState(true);

  const { matchID } = useParams();

  let [teamA, teamB] = window.location.href.split("?")[1].split("&");

  teamA = teamA.split("=")[1];
  teamB = teamB.split("=")[1];

  const getX = async () => {
    try {
      const data = await api.get("/x/" + matchID);
      setX(data);
      setTeamAChance(parseFloat(data.teamAChance));
    } catch (err) {
      console.log("🚀 ~ getMatch ~ err:", err);
    }
  };

  const updateTeamX = async () => {
    try {
      await api.post("/x/update", {
        teamAChance,
        _id: x._id,
        teamA: teamAX,
        teamB: teamBX,
      });
      window.M.toast({ html: "Team X Updated" });
    } catch (err) {
      console.log("🚀 ~ updateTeamX ~ err:", err);
    }
  };

  const updateButtonX = async () => {
    try {
      await api.post("/x/update", {
        _id: x._id,
        buttons: JSON.stringify(x.buttons),
      });
      window.M.toast({ html: "Button X Updated" });
    } catch (err) {
      console.log("🚀 ~ updateTeamX ~ err:", err);
    }
  };

  const lock = async (team) => {
    try {
      const d = {
        _id: x._id,
      };
      d[team + "Locked"] = x[team + "Locked"] === 0 ? 1 : 0;
      await api.post("/x/update", d);
      getX();
    } catch (err) {
      console.log("🚀 ~ lock ~ err:", err);
    }
  };

  const updateSessions = async () => {
    try {
      await api.post("/update-sessions-status", {
        _id: x._id,
        sessionsBlocked: x.sessionsBlocked == 0 ? 1 : 0,
      });
      getX();
    } catch (err) {
      console.log("🚀 ~ updateSessions ~ err:", err);
    }
  };

  useEffect(() => {
    const moreChance = teamAChance > teamBChance ? "teamA" : "teamB";
    const tbc = 100 - teamAChance;
    if (moreChance === "teamA") {
      const diff = (teamAChance - 50) * 2;
      const x = tbc <= 5 ? 4 : tbc <= 10 ? 3 : 2;
      setTeamAX((((teamAChance - diff) * x) / 50).toFixed(3));
      setTeamBX((((tbc + diff) * x) / 50).toFixed(3));
    } else {
      const diff = (tbc - 50) * 2;
      const x = teamAChance <= 5 ? 4 : teamAChance <= 10 ? 3 : 2;
      setTeamAX((((parseInt(teamAChance) + diff) * x) / 50).toFixed(3));
      setTeamBX((((tbc - diff) * x) / 50).toFixed(3));
    }

    setTeamBChance(tbc);
    setTimeout(() => {
      setFirstTime(false);
    }, 2000);
    if (!firstTime) {
      const t = setTimeout(() => {
        teamAChance > 0 && updateTeamX();
      }, 1000);
      return () => clearTimeout(t);
    }
  }, [teamAChance]);

  useEffect(() => {
    getX();
  }, [matchID]);

  useEffect(() => {
    !firstTime && updateButtonX();
    setTimeout(() => {
      setFirstTime(false);
    }, 2000);
  }, [x]);

  return (
    <>
      {x && (
        <div className="manage">
          <h5>Team A Winning Chance</h5>
          <br />
          <input
            type="range"
            value={teamAChance}
            min={1}
            max={100}
            onChange={({ currentTarget }) =>
              setTeamAChance(currentTarget.value)
            }
          />
          <div className="range-items">
            <span>
              Team A ({teamA}): {teamAChance} ({teamAX}X)
            </span>
            <span>
              Team B ({teamB}): {teamBChance} ({teamBX}X)
            </span>
          </div>
          <br />
          <div>
            <button
              className={"btn " + (x.teamALocked === 0 && "red")}
              onClick={() => lock("teamA")}
            >
              {x.teamALocked === 1 ? "open" : "close"} {teamA}
            </button>
            <button
              className={"btn " + (x.teamBLocked === 0 && "red")}
              style={{ marginLeft: 20 }}
              onClick={() => lock("teamB")}
            >
              {x.teamBLocked === 1 ? "open" : "close"} {teamB}
            </button>
            <button
              className={"btn " + (x.sessionsBlocked === 0 && "red")}
              style={{ marginLeft: 100 }}
              onClick={() => updateSessions()}
            >
              {x.sessionsBlocked === 1 ? "open" : "close"} Sessions
            </button>
          </div>
          <br />
          <table>
            <tbody>
              <tr>
                <th>Ball</th>
                <th>X</th>
                <th>Status</th>
              </tr>
              {x.buttons.map((item, index) => (
                <tr>
                  <td>{item.title}</td>
                  <td>
                    <input
                      defaultValue={item.x}
                      type="number"
                      style={{ width: 100 }}
                      onBlur={({ currentTarget: { value } }) => {
                        x.buttons[index].x = parseFloat(value);
                        setX((prev) => ({ ...prev, ...x }));
                      }}
                    />
                  </td>
                  <td>
                    <Switch
                      checked={!item.locked}
                      onChange={() => {
                        const status = !x.buttons[index].locked;
                        x.buttons[index].locked = status;
                        setX((prev) => ({ ...prev, ...x }));
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <br />
        </div>
      )}
    </>
  );
};

export default Manage;
