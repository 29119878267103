import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";

import TabBar from "../../components/tabBar";

import api from "../../functions/api";
import Paginations from "../../components/paginations";

const StakeList = () => {
  const { matchID } = useParams();

  const TeamStake = () => {
    const [data, setData] = useState([]);

    const getData = async (page) => {
      try {
        const res = await api.get(
          `/match-bet-list?page=${page || 1}&numOfItem=${10}&match=${matchID}`
        );
        setData(res);
      } catch (err) {
        console.log("🚀 ~ getData ~ err:", err);
      }
    };

    useEffect(() => {
      getData();
    }, [matchID]);

    return (
      <div>
        <br />
        <table>
          <tbody>
            <tr>
              <th>Sr. No.</th>
              <th>ID</th>
              <th>User</th>
              <th>Match</th>
              <th>Selected Team</th>
              <th>Amount</th>
              <th>X</th>
              <th>Status</th>
              <th>Time</th>
            </tr>
            {data.map((item, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{item._id}</td>
                <td>{item.user}</td>
                <td>{item.vs}</td>
                <td>{item.selectedTeamName}</td>
                <td>{item.amount}</td>
                <td>{item.x}</td>
                <td>{item.status}</td>
                <td>{moment(item.createdAt).format("DD/MM/YY hh:mm:ss a")}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <Paginations onChange={getData} />
      </div>
    );
  };

  const BallStake = () => {
    const [data, setData] = useState([]);

    const getData = async (page) => {
      try {
        const res = await api.get(
          `/ball-bet-list?page=${page || 1}&numOfItem=${10}&match=${matchID}`
        );
        setData(res);
      } catch (err) {
        console.log("🚀 ~ getData ~ err:", err);
      }
    };

    useEffect(() => {
      getData();
    }, [matchID]);

    return (
      <div>
        <br />
        <table>
          <tbody>
            <tr>
              <th>Sr. No.</th>
              <th>ID</th>
              <th>User</th>
              <th>Match</th>
              <th>Selected Ball</th>
              <th>Amount</th>
              <th>X</th>
              <th>Over</th>
              <th>Result</th>
              <th>Status</th>
              <th>Time</th>
            </tr>
            {data.map((item, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{item._id}</td>
                <td>{item.user}</td>
                <td>{item.vs}</td>
                <td>{item.value}</td>
                <td>{item.betAmount}</td>
                <td>{item.x}</td>
                <td>{item._over}</td>
                <td>{item.result || "None"}</td>
                <td>{item.status}</td>
                <td>{moment(item.createdAt).format("DD/MM/YY hh:mm:ss a")}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <Paginations onChange={getData} />
      </div>
    );
  };

  const BuyPlayer = () => {
    const [data, setData] = useState([]);

    const getData = async (page) => {
      try {
        const res = await api.get(
          `/buy-players?page=${page || 1}&numOfItem=${10}&match=${matchID}`
        );
        setData(res);
      } catch (err) {
        console.log("🚀 ~ getData ~ err:", err);
      }
    };

    useEffect(() => {
      getData();
    }, [matchID]);

    return (
      <div>
        <br />
        <table>
          <tbody>
            <tr>
              <th>Sr. No.</th>
              <th>ID</th>
              <th>User</th>
              <th>Team</th>
              <th>Player</th>
              <th>Buy Price</th>
              <th>Quantity</th>
              <th>Points</th>
              <th>PL</th>
              <th>Time</th>
            </tr>
            {data.map((item, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{item._id}</td>
                <td>{item.user}</td>
                <td>{item.info.teamName}</td>
                <td>{item.info.shortName}</td>
                <td>{item.info.price}</td>
                <td>{item.quantity}</td>
                <td>{item.points.totalPoints}</td>
                <td>
                  {parseFloat(item.points.totalPoints) -
                    parseFloat(item.info.price)}
                </td>
                <td>{moment(item.createdAt).format("DD/MM/YY hh:mm:ss a")}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <Paginations onChange={getData} />
      </div>
    );
  };

  const RunRate = () => {
    const [data, setData] = useState([]);

    const getData = async (page) => {
      try {
        const res = await api.get(
          `/run-rate-bet-list?page=${
            page || 1
          }&numOfItem=${10}&match=${matchID}`
        );
        setData(res);
      } catch (err) {
        console.log("🚀 ~ getData ~ err:", err);
      }
    };

    useEffect(() => {
      getData();
    }, [matchID]);

    return (
      <div>
        <br />
        <table>
          <tbody>
            <tr>
              <th>Sr. No.</th>
              <th>ID</th>
              <th>User</th>
              <th>Match</th>
              <th>Selected</th>
              <th>Amount</th>
              <th>Quantity</th>
              <th>Over</th>
              <th>Current Runrate</th>
              <th>Result</th>
              <th>Status</th>
              <th>Time</th>
            </tr>
            {data.map((item, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{item._id}</td>
                <td>{item.user}</td>
                <td>{item.vs}</td>
                <td>{item.selected}</td>
                <td>{item.amount}</td>
                <td>{item.quantity}</td>
                <td>{item._over}</td>
                <td>{item.curRunRate}</td>
                <td>{item.result || "None"}</td>
                <td>{item.status}</td>
                <td>{moment(item.createdAt).format("DD/MM/YY hh:mm:ss a")}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <Paginations onChange={getData} />
      </div>
    );
  };

  const Sessions = () => {
    const [data, setData] = useState([]);

    const getData = async (page) => {
      try {
        const res = await api.get(
          `/all-sessions?page=${page || 1}&numOfItem=${10}&match=${matchID}`
        );
        setData(res);
      } catch (err) {
        console.log("🚀 ~ getData ~ err:", err);
      }
    };

    useEffect(() => {
      getData();
    }, [matchID]);

    return (
      <div>
        <br />
        <table>
          <tbody>
            <tr>
              <th>Sr. No.</th>
              <th>ID</th>
              <th>User</th>
              <th>Question</th>
              <th>Selected</th>
              <th>Amount</th>
              <th>Result</th>
              <th>X</th>
              <th>Status</th>
              <th>Time</th>
            </tr>
            {data.map((item, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{item._id}</td>
                <td>{item.user}</td>
                <td>{item.question}</td>
                <td style={{ color: item.yesorno == 0 ? "red" : "green" }}>
                  {item.number}
                </td>
                <td>{item.amount}</td>
                <td>{item.result || "None"}</td>
                <td>{item.x}</td>
                <td>{item.status}</td>
                <td>{moment(item.createdAt).format("DD/MM/YY hh:mm:ss a")}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <Paginations onChange={getData} />
      </div>
    );
  };

  return (
    <div className="recharge-list">
      <TabBar
        tabs={[
          { title: "Team Stake", component: () => <TeamStake /> },
          { title: "Sessions", component: () => <Sessions /> },
          { title: "Ball Stake", component: () => <BallStake /> },
          { title: "Buy Player", component: () => <BuyPlayer /> },
          { title: "Runrate Scalping", component: () => <RunRate /> },
        ]}
      />
    </div>
  );
};

export default StakeList;
