import { useState, useEffect } from "react";
import { TextInput } from "react-materialize";
import moment from "moment";

import TabBar from "../../components/tabBar";
import Model from "../../components/model";

import Paginations from "../../components/paginations";

import api from "../../functions/api";
import { Link } from "react-router-dom";

const Matches = () => {
  const MatchList = ({ status }) => {
    const [data, setData] = useState([]);

    const getData = async (page = 1) => {
      const res = await api.get(
        `/matches/?status=${status}${
          status === "Completed" ? "&desc=yes" : ""
        }&page=${page}&numOfItem=${10}`,
        true
      );
      setData(res);
    };

    useEffect(() => {
      getData();
    }, [status]);

    return (
      <div className="recharge-list">
        <TextInput
          label="Search by User ID"
          onChange={(text) => getData(1, text.currentTarget.value, true)}
        />
        <table>
          <tbody>
            <tr>
              <th>Sr. No. </th>
              <th>ID</th>
              <th>Match</th>
              <th>Tournament</th>
              <th>Team A</th>
              <th>Team B</th>
              <th>Start Time</th>
              <th>Status</th>
              <th>Contests</th>
              <th>Stake List</th>
              <th>Sessions</th>
              <th>Manage</th>
              <th>Analytics</th>
            </tr>
            {data.map((item, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{item._id}</td>
                <td>{item.teamAShortName + " vs " + item.teamBShortName}</td>
                <td>{item.tournamentName}</td>
                <td>{item.teamAShortName}</td>
                <td>{item.teamBShortName}</td>
                <td>{moment(item.startTime).format("DD/MM/YY hh:mm:ss a")}</td>
                <td>{item.status}</td>
                <th>
                  <Link className="btn" to={"contests/" + item._id}>
                    Contests
                  </Link>
                </th>
                <th>
                  <Link to={"stake-list/" + item._id} className="btn">
                    stake list
                  </Link>
                </th>
                <th>
                  <Link
                    className="btn"
                    to={`sessions/${item._id}/${item[item.bettingTeam]}`}
                  >
                    Sessions
                  </Link>
                </th>
                <th>
                  <Link
                    className="btn"
                    to={
                      item.status == "Completed"
                        ? ""
                        : `manage/${item._id}?teamA=${item.teamAShortName}&teamB=${item.teamBShortName}`
                    }
                  >
                    Manage
                  </Link>
                </th>
                <th>
                  <Link className="btn" to={`analytics/${item._id}`}>
                    Analytics
                  </Link>
                </th>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <Paginations onChange={getData} />
      </div>
    );
  };

  return (
    <div className="recharges">
      <TabBar
        tabs={[
          {
            title: "Upcoming",
            component: () => <MatchList status="Upcoming" />,
          },
          {
            title: "Live",
            component: () => <MatchList status="Live" />,
          },
          {
            title: "Completed",
            component: () => <MatchList status="Completed" />,
          },
        ]}
      />
    </div>
  );
};

export default Matches;
