const Model = ({ show, onHide = () => {}, children }) => {
  return (
    <>
      {show && (
        <>
          <div className="model" onClick={() => onHide(false)} />
          <div className="model-content">{children}</div>
        </>
      )}
    </>
  );
};

export default Model;
