import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import Login from "./components/login";
import Icon from "./components/icon";

import Users from "./pages/app/users";
import Recharges from "./pages/app/recharges";

import { setPin, setTime, setUser } from "./redux/slices";
import PinLogin from "./components/pinLogin";
import WithDraws from "./pages/app/withdraws";
import Matches from "./pages/app/matches";
import Contests from "./pages/app/contests";
import StakeList from "./pages/app/stakeList";
import Analytics from "./pages/app/analytics";
import Agents from "./pages/app/agents";
import AgentRecharges from "./pages/app/agentRecharges";
import AgentAllRecharges from "./pages/app/agentAllRecharges";
import Manage from "./pages/app/manage";
import Sessions from "./pages/app/sessions";
import MatchAnalytics from "./pages/app/matchAnalytics";

const Navigations = () => {
  const user = useSelector((s) => s.user.value);
  const pin = useSelector((s) => s.pin.value);
  const time = useSelector((s) => s.time.value);

  const dispatch = useDispatch();

  const navs = [
    { title: "Analytics", path: "/analytics", icon: "monitoring" },
    { title: "Users", path: "/users", icon: "group" },
    { title: "Matches", path: "/matches", icon: "sports_cricket" },
    { title: "Recharges", path: "/recharges", icon: "monetization_on" },
    { title: "Withdraws", path: "/withdraws", icon: "currency_rupee" },
    { title: "Agents", path: "/agents", icon: "real_estate_agent" },
  ];

  const logout = () => {
    const confirm = window.confirm("Are you sure you want to logout?");

    if (!confirm) return;

    dispatch(setPin(false));
    dispatch(setUser(null));
    dispatch(setTime(new Date()));
  };

  useEffect(() => {
    if (!time || new Date() >= new Date(time)) dispatch(setPin(false));
  }, []);

  return (
    <BrowserRouter>
      {!user ? (
        <Login />
      ) : (
        <div className="cont">
          {!pin ? (
            <PinLogin />
          ) : (
            <>
              <div className="side-bar">
                <div className="navs">
                  {navs.map((item, index) => (
                    <NavLink
                      key={index}
                      to={item.path}
                      className={({ isActive }) => isActive && "nav-active"}
                    >
                      <Icon name={item.icon} />
                      {item.title}
                    </NavLink>
                  ))}
                </div>
                <button className="btn red logout" onClick={logout}>
                  logout
                </button>
              </div>
              <div className="routes">
                <Routes>
                  <Route path="/users" element={<Users />} />
                  <Route path="/analytics" element={<Analytics />} />
                  <Route path="/recharges" element={<Recharges />} />
                  <Route path="/withdraws" element={<WithDraws />} />
                  <Route path="/matches" element={<Matches />} />
                  <Route path="/agents" element={<Agents />} />
                  <Route
                    path="/agents/recharges"
                    element={<AgentAllRecharges />}
                  />
                  <Route
                    path="/agents/recharges/:user"
                    element={<AgentRecharges />}
                  />
                  <Route
                    path="/matches/contests/:matchID"
                    element={<Contests />}
                  />
                  <Route
                    path="/matches/stake-list/:matchID"
                    element={<StakeList />}
                  />
                  <Route path="/matches/manage/:matchID" element={<Manage />} />
                  <Route
                    path="/matches/sessions/:matchID/:team"
                    element={<Sessions />}
                  />
                  <Route
                    path="/matches/analytics/:match"
                    element={<MatchAnalytics />}
                  />
                </Routes>
              </div>
            </>
          )}
        </div>
      )}
    </BrowserRouter>
  );
};

export default Navigations;
