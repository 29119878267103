import { useEffect, useState } from "react";
import moment from "moment";
import { TextInput } from "react-materialize";

import Paginations from "../../components/paginations";
import Model from "../../components/model";

import api from "../../functions/api";
import { Link } from "react-router-dom";

const Agents = () => {
  const [users, setUsers] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [fullName, setFullName] = useState(null);
  const [mobNo, setMobNo] = useState(null);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [pin, setPin] = useState(null);
  const [per, setPer] = useState(null);
  const [profit, setProfit] = useState(null);
  const [_id, setId] = useState(null);
  const [amount, setAmount] = useState(0);
  const [recharge, setRecharge] = useState(0);
  const [sm, setSm] = useState(null);

  const getUsers = async (page, search, id) => {
    const res = await api.get(
      `/agents?page=${page || 1}&numOfItem=${10}&mobNo=${search || null}&id=${
        id ? search : ""
      }`
    );
    setUsers(res);
  };

  const updateUser = async (user) => {
    const confirm = window.confirm(
      `Are you sure you want to ${
        user.status === 0 ? "Deactivate" : "Activate"
      } agent?`
    );

    if (!confirm) return;

    try {
      await api.post("/add-agent", {
        _id: user._id,
        status: user.status === 0 ? 1 : 0,
      });
      window.M.toast({
        html: `User ${user.status === 0 ? "Deactivated" : "Activated"}`,
      });
      getUsers();
    } catch (err) {
      console.log("🚀 ~ updateUser ~ err:", err);
    }
  };

  const addAgent = async (e) => {
    e.preventDefault();
    try {
      await api.post("/add-agent", {
        _id,
        name: fullName,
        mobNo,
        username,
        password,
        pin,
        profit,
        per,
      });
      getUsers();
      setShowModel(false);
      setId(null);
      window.M.toast({ html: `Agent ${_id ? "Updated" : "Added"}` });
    } catch (err) {
      console.log("🚀 ~ addAgent ~ err:", err);
    }
  };

  const addBalance = async (e) => {
    e.preventDefault();
    try {
      await api.post("/add-agent-recharge", {
        _id,
        amount,
        recharge,
      });
      getUsers();
      setSm(false);
      setId(null);
      window.M.toast({ html: "Recharge added" });
    } catch (err) {
      console.log("🚀 ~ addAgent ~ err:", err);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="users">
      <Model show={showModel} onHide={setShowModel}>
        <form className="card" onSubmit={addAgent}>
          <h2>Add/Edit Agent</h2>
          <TextInput
            label="Full Name"
            required
            value={fullName}
            validate
            onChange={(e) => setFullName(e.currentTarget.value)}
          />
          <TextInput
            label="Mobile Number"
            required
            value={mobNo}
            validate
            onChange={(e) => setMobNo(e.currentTarget.value)}
          />
          <TextInput
            label="Username"
            required
            value={username}
            validate
            onChange={(e) => setUsername(e.currentTarget.value)}
          />
          <TextInput
            label="Password"
            required
            validate
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
          />
          <TextInput
            label="PIN"
            required
            value={pin}
            validate
            onChange={(e) => setPin(e.currentTarget.value)}
          />

          <TextInput
            label="Share Percentage"
            required
            value={per}
            validate
            onChange={(e) => setPer(e.currentTarget.value)}
          />

          <select
            onChange={(e) => setProfit(e.currentTarget.value)}
            value={profit}
          >
            <option disabled selected>
              Share Type
            </option>
            <option value={0}>Only Profit</option>
            <option value={1}>Profit & Loss</option>
            <option value={2}>Equal</option>
          </select>
          <br />

          <button className="btn">Save</button>
        </form>
      </Model>
      <Model show={sm} onHide={setSm}>
        <form className="card" onSubmit={addBalance}>
          <h2>Add Balance</h2>
          <TextInput
            label="Cash Amount"
            required
            value={amount}
            validate
            onChange={(e) => {
              e = e.currentTarget.value;
              if (!e.match(/^\d*|\b$|^$/)) return;
              setAmount(e);
            }}
          />
          <TextInput
            label="Recharge"
            required
            validate
            value={recharge}
            onChange={(e) => {
              e = e.currentTarget.value;
              if (!e.match(/^\d*|\b$|^$/)) return;
              setRecharge(e);
            }}
          />
          <button className="btn">Save</button>
        </form>
      </Model>
      <TextInput
        label="Search User by Mobile Number"
        onChange={(text) => getUsers(1, text.currentTarget.value)}
      />
      <TextInput
        label="Search User by ID"
        onChange={(text) => getUsers(1, text.currentTarget.value, true)}
      />
      <div style={{ flexDirection: "row" }}>
        <button
          className="btn"
          onClick={() => {
            setId(null);
            setShowModel(true);
            setUsername(null);
            setPassword(null);
            setFullName(null);
            setPin(null);
            setMobNo(null);
          }}
        >
          Add Agent
        </button>

        <Link className="btn" style={{ marginLeft: 100 }} to={"recharges"}>
          Recharge History
        </Link>
      </div>
      <br />
      <table>
        <tbody>
          <tr>
            <th>Sr. No.</th>
            <th>ID</th>
            <th>Name</th>
            <th>Mobile Number</th>
            <th>Wallet</th>
            <th>Recharges</th>
            <th>Action</th>
            <th>Add Recharge</th>
            <th>Edit</th>
            <th>Joined Time</th>
          </tr>
          {users.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item._id}</td>
              <td>{item.name}</td>
              <td>{item.mobNo}</td>
              <td>{item.wallet || 0}</td>
              <td>
                <Link className={"btn"} to={"recharges/" + item._id}>
                  Recharges
                </Link>
              </td>
              <td>
                <button
                  className={"btn " + (item.status === 0 && "blue")}
                  onClick={() => {
                    updateUser(item);
                  }}
                >
                  {item.status === 0 ? "Deactivate" : "Activate"}
                </button>
              </td>

              <td>
                <button
                  className={"btn"}
                  onClick={() => {
                    setAmount(null);
                    setRecharge(null);
                    setId(item._id);
                    setSm(true);
                  }}
                >
                  Add Recharge
                </button>
              </td>

              <td>
                <button
                  className={"btn"}
                  onClick={() => {
                    setId(item._id);
                    setFullName(item.name);
                    setMobNo(item.mobNo);
                    setUsername(item.username);
                    setPassword(item.password);
                    setPin(item.pin);
                    setProfit(item.profit);
                    setPer(item.per);
                    setShowModel(true);
                  }}
                >
                  EDIT
                </button>
              </td>
              <td>{moment(item.createdAt).format("DD/MM/YY hh:mm:ss a")}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Paginations onChange={getUsers} />
    </div>
  );
};

export default Agents;
