import { Pagination } from "react-materialize";
import Icon from "./icon";

const Paginations = ({ onChange }) => {
  return (
    <Pagination
      items={100000000 * 100000}
      leftBtn={<Icon name="chevron_left" />}
      rightBtn={<Icon name="chevron_right" />}
      onSelect={onChange}
      maxButtons={10}
    />
  );
};

export default Paginations;
