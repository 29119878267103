import axios from "axios";

const api = {
  post: async (path, data, isScoreAPI, isMUltipart) =>
    new Promise(async (resolve, reject) => {
      try {
        const { data: res } = await axios({
          method: "post",
          url: isScoreAPI
            ? "https://score-apis.crickstox.com" + path
            : "https://apis.crickstox.com" + path,
          data,
          headers: {
            "Content-Type": isMUltipart
              ? "multipart/form-data"
              : "application/json",
          },
        });
        if (res.status === "SUCCESS") {
          resolve(res.data);
        } else {
          window.M.toast({ html: res.msg || "Something went wrong" });
          reject(res.msg || "Something went wrong");
        }
      } catch (err) {
        reject(err);
        return window.M.toast({ html: "Something went wrong" });
      }
    }),
  get: async (path, isScoreAPI) =>
    new Promise(async (resolve, reject) => {
      try {
        const { data: res } = await axios({
          method: "get",
          url: isScoreAPI
            ? "https://score-apis.crickstox.com" + path
            : "https://apis.crickstox.com" + path,
        });
        if (res.status === "SUCCESS") {
          resolve(res.data);
        } else {
          window.M.toast({ html: res.msg || "Something went wrong" });
          reject(res.msg || "Something went wrong");
        }
      } catch (err) {
        reject(err);
        return window.M.toast({ html: "Something went wrong" });
      }
    }),
};

export const scoreBaseURL = "https://score-apis.crickstox.com";
export const apiBaseURL = "https://apis.crickstox.com";

export default api;
