import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import api from "../functions/api";

import { setPin, setTime } from "../redux/slices";
import OTPInput from "react-otp-input";

const PinLogin = () => {
  const [pin, setpin] = useState("");

  const dispatch = useDispatch();

  const user = useSelector((s) => s.user.value);

  const login = async () => {
    try {
      await api.post("/admin-pin-login", { pin, _id: user });

      const currentDate = new Date();

      currentDate.setHours(currentDate.getHours() + 2);

      dispatch(setPin(true));
      dispatch(setTime(currentDate));
    } catch (err) {
      console.log("🚀 ~ login ~ err:", err);
    }
  };

  useEffect(() => {
    const sp = pin.split("");
    if (sp.length === 4) login();
  }, [pin]);

  return (
    <div className="login pin">
      <main className="card">
        <OTPInput
          value={pin}
          onChange={setpin}
          numInputs={4}
          renderInput={(props) => (
            <input {...props} type="password" autoComplete={false} />
          )}
        />
      </main>
    </div>
  );
};

export default PinLogin;
