import { useEffect, useState } from "react";
import moment from "moment";
import { TextInput } from "react-materialize";

import Paginations from "../../components/paginations";
import Model from "../../components/model";

import api from "../../functions/api";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const getUsers = async (page, search, id) => {
    const res = await api.get(
      `/users?page=${page || 1}&numOfItem=${10}&mobNo=${search || null}&id=${
        id ? search : ""
      }`
    );
    setUsers(res);
  };

  const updateUser = async (user) => {
    const confirm = window.confirm(
      `Are you sure you want to ${
        user.status === 0 ? "Deactivate" : "Activate"
      } user?`
    );

    if (!confirm) return;

    try {
      await api.post("/update-user", {
        _id: user._id,
        status: user.status === 0 ? 1 : 0,
      });
      window.M.toast({
        html: `User ${user.status === 0 ? "Deactivated" : "Activated"}`,
      });
      getUsers();
    } catch (err) {
      console.log("🚀 ~ updateUser ~ err:", err);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="users">
      <Model show={showModel} onHide={setShowModel}>
        <div className="card">
          <h2>KYC Details</h2>
          {userInfo.kyc && (
            <div>
              <span>Mobile Number: {userInfo.mobNo}</span>
              <span>Bank Account Number: {userInfo.kyc.accNo}</span>
              <span>Bank IFSC Code: {userInfo.kyc.ifscCode}</span>
              <span>UPI ID: {userInfo.kyc.upiID}</span>
              <span>State: {userInfo.kyc.state}</span>
              <span>City: {userInfo.kyc.city}</span>
              <span>Email: {userInfo.kyc.email}</span>
            </div>
          )}
        </div>
      </Model>
      <TextInput
        label="Search User by Mobile Number"
        onChange={(text) => getUsers(1, text.currentTarget.value)}
      />
      <TextInput
        label="Search User by ID"
        onChange={(text) => getUsers(1, text.currentTarget.value, true)}
      />
      <table>
        <tbody>
          <tr>
            <th>Sr. No.</th>
            <th>ID</th>
            <th>Mobile Number</th>
            <th>Wallet</th>
            <th>Ref By</th>
            <th>KYC</th>
            <th>Action</th>
            <th>Bet Records</th>
            <th>Joined Time</th>
          </tr>
          {users.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item._id}</td>
              <td>{item.mobNo}</td>
              <td>{item.wallet || 0}</td>
              <td>{item.refBy || "None"}</td>
              <td>
                <button
                  className="btn"
                  disabled={!item.kyc}
                  onClick={() => {
                    setUserInfo(item);
                    setShowModel(true);
                  }}
                >
                  View
                </button>
              </td>
              <td>
                <button
                  className={"btn " + (item.status === 0 && "blue")}
                  onClick={() => {
                    updateUser(item);
                  }}
                >
                  {item.status === 0 ? "Deactivate" : "Activate"}
                </button>
              </td>
              <th>
                <a
                  className="btn"
                  target="_blank"
                  href={`https://apis.crickstox.com/user-bet-ex?user=${item._id}`}
                >
                  Download
                </a>
              </th>

              <td>{moment(item.createdAt).format("DD/MM/YY hh:mm:ss a")}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Paginations onChange={getUsers} />
    </div>
  );
};

export default Users;
