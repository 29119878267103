import { useState, useEffect } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";

import Paginations from "../../components/paginations";
import Model from "../../components/model";

import api from "../../functions/api";
import Icon from "../../components/icon";

const Contests = () => {
  const [data, setData] = useState([]);
  const [fields, setFields] = useState([
    { pricePool: "", entryFee: "", quantity: "" },
  ]);
  const [showModel, setShowModel] = useState(false);

  const { matchID } = useParams();

  const getContsets = async (page) => {
    setData([]);
    try {
      const res = await api.get(
        "/contests/" + matchID + `?page=${page || 1}&numOfItem=${5}`
      );
      setData(res);
    } catch (err) {
      console.log("🚀 ~ getContsets ~ err:", err);
    }
  };

  const saveContests = (e) => {
    e.preventDefault();
    try {
      const prom = fields.map((item) => {
        for (let i = 0; i < item.quantity; i++) {
          (async () => {
            await api.post("/add-contest", {
              _match: matchID,
              pricePool: item.pricePool,
              entryFee: item.entryFee,
            });
          })();
        }
      });

      Promise.all(prom)
        .then(() => {
          window.M.toast({ html: "Contests added successfully" });
          setShowModel(false);
          getContsets();
        })
        .catch((err) => {
          console.log("🚀 ~ Promise.all ~ err:", err);
          window.M.toast({ html: "Error while creating contests" });
        });
    } catch (err) {
      console.log("🚀 ~ saveContests ~ err:", err);
    }
  };

  useEffect(() => {
    getContsets();
  }, []);

  return (
    <div className="recharge-list contests">
      <Model show={showModel} onHide={setShowModel}>
        <div className="card">
          <h2>Create Contests</h2>
          <button
            className="btn-floating"
            style={{
              width: 50,
              height: 50,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => {
              const _fields = [...fields];
              _fields.push({ entryFee: "", pricePool: "", quantity: "" });
              setFields(_fields);
            }}
          >
            <Icon name="add" />
          </button>
          <br />

          <form onSubmit={saveContests}>
            <div className="fields">
              {fields.map((item, index) => (
                <div className="field">
                  <input
                    placeholder="Entry Fee"
                    value={item.entryFee}
                    className="validate"
                    required
                    onChange={({ currentTarget: { value } }) => {
                      const _fields = [...fields];
                      _fields[index].entryFee = value;
                      setFields(_fields);
                    }}
                  />
                  <input
                    placeholder="Price Pool"
                    value={item.pricePool}
                    className="validate"
                    required
                    onChange={({ currentTarget: { value } }) => {
                      const _fields = [...fields];
                      _fields[index].pricePool = value;
                      setFields(_fields);
                    }}
                  />
                  <input
                    placeholder="Quanity"
                    type="number"
                    value={item.quantity}
                    className="validate"
                    required
                    onChange={({ currentTarget: { value } }) => {
                      const _fields = [...fields];
                      _fields[index].quantity = value;
                      setFields(_fields);
                    }}
                  />
                  {fields.length > 1 && (
                    <div
                      className="btn-floating red"
                      style={{
                        width: 30,
                        height: 30,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        let _fields = [...fields];
                        _fields = _fields.filter((_, i) => i !== index);
                        setFields(_fields);
                      }}
                    >
                      <Icon name="remove" />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <br />
            <button className="btn" type="submit">
              Create
            </button>
          </form>
        </div>
      </Model>
      <button className="btn" onClick={() => setShowModel(true)}>
        + Add Contests
      </button>
      <br />
      <br />
      <table>
        <tbody>
          <tr>
            <th>Sr. No. </th>
            <th>ID</th>
            <th>Entry Fee</th>
            <th>Price Pool</th>
            <th>Profit</th>
            <th>Spot</th>
            <th>User A</th>
            <th>User B</th>
            <th>Created Time</th>
          </tr>
          {data.map((item, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{item._id}</td>
              <td>{item.entryFee}</td>
              <td>{item.pricePool}</td>
              <td>
                {parseFloat(item.entryFee) * 2 - parseFloat(item.pricePool)}
              </td>
              <td className={item.spotLeft === 0 && "green-text"}>
                {item.spotLeft === 0 ? "Full" : item.spotLeft + " Left"}
              </td>
              <td>{item.users[0] ? item.users[0].user : "None"}</td>
              <td>{item.users[1] ? item.users[1].user : "None"}</td>
              <td>{moment(item.createdAt).format("DD/MM/YY hh:mm:ss a")}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />
      <Paginations onChange={getContsets} />
    </div>
  );
};

export default Contests;
