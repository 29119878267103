import { useState, useEffect } from "react";
import { TextInput } from "react-materialize";
import moment from "moment";

import TabBar from "../../components/tabBar";
import Model from "../../components/model";

import Paginations from "../../components/paginations";

import api from "../../functions/api";

const Recharges = () => {
  const RechargeList = ({ status }) => {
    const [data, setData] = useState([]);
    const [date, setDate] = useState(null);
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [showModel, setShowModel] = useState(false);
    const [recharge, setRecharge] = useState({});

    const filters = [
      { label: "Today", value: "today" },
      { label: "Yesterday", value: "yesterday" },
      { label: "This Week", value: "thisWeek" },
      { label: "This Month", value: "thisMonth" },
    ];

    const getData = async (page, search, id) => {
      const res = await api.get(
        `/all-recharges?page=${page || 1}&numOfItem=${10}&status=${
          status || ""
        }&id=${id ? search : ""}&date=${date || ""}&from=${from || ""}&to=${
          to || ""
        }`
      );
      setData(res);
    };

    const updateRechargeStatus = async (status) => {
      setShowModel(false);
      const confirm = window.confirm(
        `Are you sure you want to ${status.replace(
          "FAILURE",
          "FAIL"
        )} this recharge?`
      );

      if (!confirm) return;

      try {
        await api.post("/add-recharge", {
          _id: recharge._id,
          status,
          user: recharge.user,
          amount: recharge.amount,
        });
        getData();
        window.M.toast({ html: "Recharge Status Updated" });
      } catch (err) {
        console.log("🚀 ~ updateRechargeStatus ~ err:", err);
      }
    };

    useEffect(() => {
      getData();
    }, [date, to, from, status]);

    return (
      <div className="recharge-list">
        <Model show={showModel} onHide={setShowModel}>
          <div className="card">
            <h2>Update Recharge STATUS</h2>
            <div>
              <button
                className="btn"
                onClick={() => updateRechargeStatus("SUCCESS")}
              >
                SUCCESS
              </button>
              <button
                className="btn red"
                onClick={() => updateRechargeStatus("FAILURE")}
              >
                FAIL
              </button>
            </div>
          </div>
        </Model>
        <TextInput
          label="Search by User ID"
          onChange={(text) => getData(1, text.currentTarget.value, true)}
        />
        <div className="filteres">
          {filters.map((item) => (
            <div
              className={`filter ${
                item.value === date && item.value && "active"
              }`}
              onClick={() => setDate(item.value)}
            >
              {item.label}
            </div>
          ))}

          <div className="date-picker">
            <TextInput
              label="From"
              type="date"
              value={from}
              onChange={(e) => {
                setFrom(e.currentTarget.value);
                setDate(null);
              }}
            />
          </div>

          <div className="date-picker">
            <TextInput
              label="To"
              type="date"
              value={to}
              onChange={(e) => {
                setTo(e.currentTarget.value);
                setDate(null);
              }}
            />
          </div>

          <button
            className="btn"
            onClick={() => {
              setDate(null);
              setFrom(null);
              setTo(null);
            }}
            style={{ marginLeft: 30 }}
          >
            Clear
          </button>
        </div>
        <table>
          <tbody>
            <tr>
              <th>Sr. No. </th>
              <th>ID</th>
              <th>User ID</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Type</th>
              <th>Txn ID</th>
              <th>Txn Ref</th>
              <th>Action</th>
              <th>Time</th>
            </tr>
            {data.map((item, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{item._id}</td>
                <td>{item.user}</td>
                <td>{item.amount}</td>
                <td>{item.status}</td>
                <td>{item.type}</td>
                <td>{item.txnId}</td>
                <td>{item.txnRef}</td>
                <td>
                  <button
                    className="btn"
                    disabled={item.status !== "PENDING"}
                    onClick={() => {
                      setShowModel(true);
                      setRecharge(item);
                    }}
                  >
                    ACTION
                  </button>
                </td>
                <td>{moment(item.createdAt).format("DD/MM/YY hh:mm:ss a")}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <Paginations onChange={getData} />
      </div>
    );
  };

  return (
    <div className="recharges">
      <TabBar
        tabs={[
          {
            title: "pending",
            component: () => <RechargeList status="PENDING" />,
          },
          {
            title: "success",
            component: () => <RechargeList status="SUCCESS" />,
          },
          { title: "fail", component: () => <RechargeList status="FAILURE" /> },
          { title: "All", component: () => <RechargeList /> },
        ]}
      />
    </div>
  );
};

export default Recharges;
