import { useState } from "react";

const TabBar = ({ tabs = [] }) => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <div className="tab-bar">
      <div className="tabs">
        {tabs.map((item, index) => (
          <div
            className={`tab ${tabIndex === index && "active"}`}
            onClick={() => setTabIndex(index)}
            style={{ width: 100 / tabs.length + "%" }}
          >
            {item.title}
          </div>
        ))}
      </div>
      {tabs[tabIndex].component()}
    </div>
  );
};

export default TabBar;
