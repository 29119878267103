import { useEffect, useState } from "react";
import api from "../../functions/api";
import { useParams } from "react-router-dom";

const Sessions = () => {
  const [data, setData] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [sessionsTab, setSessionsTab] = useState(0);
  const [value, setValue] = useState(null);

  const { matchID } = useParams();

  const sessionsFilter = [
    { name: "All", value: null },
    { name: "Sessions", value: 0 },
    { name: "Fancy", value: 1 },
  ];

  const getSessions = async () => {
    try {
      const data = await api.get("/sessions-list/" + matchID);
      setData(data);
      setSessionsTab(0);
      setSessions(data);
      setValue(null);
    } catch (err) {
      console.log("🚀 ~ getSessions ~ err:", err);
    }
  };

  const submitResult = async (para) => {
    try {
      await api.post("/submit-session-result", para);
      window.M.toast({ html: "Result submitted" });
      getSessions();
    } catch (err) {
      console.log("🚀 ~ submitResult ~ err:", err);
    }
  };

  useEffect(() => {
    getSessions();
  }, [matchID]);

  return (
    <div>
      <div className="sessions-filter">
        {sessionsFilter.map((item, index) => (
          <div
            className={"sf " + (sessionsTab === index && "active")}
            onClick={() => {
              setSessions(
                item.value ? data.filter((i) => i._type === item.value) : data
              );
              setSessionsTab(index);
            }}
          >
            {item.name}
          </div>
        ))}
        <button
          className="btn"
          style={{ marginLeft: "auto", marginRight: 20 }}
          onClick={getSessions}
        >
          Refresh
        </button>
      </div>

      <div className="sessions-filter">
        <div className={"sf "} onClick={() => setSessions(data)}>
          All
        </div>
        <div
          className={"sf "}
          onClick={() =>
            setSessions(data.filter((i) => i.status === "PENDING"))
          }
        >
          Pending
        </div>
        <div
          className={"sf "}
          onClick={() =>
            setSessions(data.filter((i) => i.status !== "PENDING"))
          }
        >
          Completed
        </div>
      </div>

      <table>
        <tbody>
          <tr>
            <th>Sr. No.</th>
            <th>Session ID</th>
            <th>Question</th>
            <th>Status</th>
            <th>Result</th>
          </tr>
          {sessions.map((item, index) => {
            return (
              <tr>
                <td>{index + 1}</td>
                <td>{item.sessionID}</td>
                <td>{item.question}</td>
                <td>{item.status !== "PENDING" ? "COMPLETED" : item.status}</td>
                <td>
                  <input
                    type="number"
                    style={{ width: 100, marginRight: 15 }}
                    onChange={(e) => setValue(e.currentTarget.value)}
                  />
                  <button
                    className="btn"
                    onClick={() =>
                      submitResult({
                        sessionID: item.sessionID,
                        _match: matchID,
                        result: value,
                      })
                    }
                    disabled={!value}
                  >
                    Submit
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Sessions;
